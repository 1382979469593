import React from "react";

const SuccessPage = () => {
  return (
    <div>
      <h2>Success</h2>
    </div>
  );
};

export default SuccessPage;
